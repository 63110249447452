import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { ProductRecommendations as RecommendationsSchema } from '../../../amplienceTypes/schemas/imported/product-recommendations-schema';
import { RecommendationsComponent } from './RecommendationsComponent';
import type { Product } from '../../../utilities/graphql/codegen';
import { fetchProducts } from '../../../utilities/graphql/queries';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import type { RecommendationsProp } from './Recommendations';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';

export const CompleteTheLook = (
  props: RecommendationsSchema & RecommendationsProp
): ReactElement => {
  const { recommendations } = props;
  const { locale, siteType } = useAppContext();
  const [recommendedProducts, setRecommendedProducts] = useState<Product[]>([]);
  const { client: apolloClient } = useApolloContext();

  useEffect(() => {
    const fetchData = async () => {
      if (recommendations?.productCodes) {
        const productCodes = recommendations.productCodes.split(',');
        const fetchedProducts = await fetchProducts({
          siteType,
          locale,
          codes: productCodes,
          apolloClient,
        });
        const ctlRecommendations = fetchedProducts?.products as Product[];

        if (ctlRecommendations) {
          const lowercaseProductCodes = productCodes.map(code => code.toLowerCase());
          const sortedProducts = [...ctlRecommendations].sort((a, b) => {
            const indexA = lowercaseProductCodes.indexOf(a.baseProduct?.toLocaleLowerCase() || '');
            const indexB = lowercaseProductCodes.indexOf(b.baseProduct?.toLocaleLowerCase() || '');

            if (indexA === -1) {
              return 1;
            }

            return indexA > indexB ? 1 : -1;
          });

          setRecommendedProducts(sortedProducts);
        }
      }
    };

    fetchData();
  }, [locale, recommendations?.productCodes, siteType, apolloClient]);

  return (
    <RecommendationsComponent {...props} recommendedProducts={recommendedProducts} showQuickView />
  );
};
