import type { ReactElement, UIEvent } from 'react';
import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Typography } from '../core/typography/Typography';
import ArrowLeftLineIcon from '../core/icons/ArrowLeftLineIcon';
import ArrowRightLineIcon from '../core/icons/ArrowRightLineIcon';
import type {
  ProductRecommendations as RecommendationsSchema,
  SectionName,
} from '../../../amplienceTypes/schemas/imported/product-recommendations-schema';
import { colors, media } from '../core/styles';
import type { Product, Recommendations } from '../../../utilities/graphql/codegen';
import type { EventProduct } from '../../../utilities/vendors';
import { getProductDataLayerEvent } from '../../../utilities/vendors';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import type { RecentlyViewedProduct } from '../../../utilities/helpers';
import { RecommendationsTile } from './RecommendationsTile';
import { Button } from '../core/button/Button';
import { hideScrollBar } from '../common/styles';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

const arrowBtnStyles = css`
  cursor: pointer;
  border: none;
  outline: 0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background-color: ${colors.BLACK};
  padding: 11px;
  position: absolute;
  top: 50%;
  z-index: 1;

  @media ${({ theme }) => media(theme).lessThan('sm')} {
    display: none;
  }
`;

const S = {
  RecommendationsWrapper: styled.div<{
    $visibleOnDesktop: boolean;
    $visibleOnTablet: boolean;
    $visibleOnMobile: boolean;
  }>`
    margin-right: 8px;
    position: relative;
    display: ${({ $visibleOnMobile }) => ($visibleOnMobile ? 'block' : 'none')};

    @media ${({ theme }) => media(theme).greaterThan('md')} {
      margin-right: 12px;
      display: ${({ $visibleOnTablet }) => ($visibleOnTablet ? 'block' : 'none')};
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      display: ${({ $visibleOnDesktop }) => ($visibleOnDesktop ? 'block' : 'none')};
    }
  `,

  CarouselWrapper: styled.div`
    ${hideScrollBar}

    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
  `,

  Title: styled(Typography)`
    padding: ${({ theme }) => theme.padding.md} ${({ theme }) => theme.padding.sm};
    text-align: center;
    font-size: ${({ theme }) => theme.font.size[7]};
    display: flex;
    justify-content: space-between;
    font-weight: ${({ theme }) => theme.overrides.font?.weight.bold};
    color: ${({ theme }) => theme.colors.content.primary};
    line-height: 1;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding: 24px 0 24px 40px;
      text-align: left;
    }
  `,

  // prettier-ignore
  Wrapper: styled.div<{ $count: number; $rowsOnMobile: string; $maxItemsDesktop: number; $maxItemsTablet: number; $maxItemsMobile: number; }>`
    display: grid;
    grid-template-columns: ${({ $count, $rowsOnMobile, $maxItemsMobile }) =>
      $rowsOnMobile === 'One' ? `repeat(${$count}, 43%)` : `repeat(${$maxItemsMobile}, 50%)`};

    @media ${({ theme }) => media(theme).greaterThan('md')} {
      grid-template-columns: ${({ $count, $maxItemsTablet }) =>
        `repeat(${$count}, ${100 / $maxItemsTablet}%)`};
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      grid-template-columns: ${({ $count, $maxItemsDesktop }) =>
        `repeat(${$count}, ${100 / $maxItemsDesktop}%)`};
    }
  `,

  LeftArrow: styled.button<{ $leftHidden: boolean }>`
    ${arrowBtnStyles}
    left: 12px;
    display: ${({ $leftHidden }) => $leftHidden && 'none'};
  `,

  RightArrow: styled.button<{ $rightHidden: boolean }>`
    ${arrowBtnStyles}
    right: 0;
    display: ${({ $rightHidden }) => $rightHidden && 'none'};
  `,

  CloseButton: styled(Button)`
    &&.button--secondary {
      background-color: unset;
      min-width: unset;
      padding: 0 10px;
      border-color: transparent;

      span {
        margin-right: 0;
      }
    }
  `,
};

interface RecommendationsProp {
  recommendedProducts: Product[];
  recommendations?: Recommendations | null;
  showQuickView?: boolean;
  ariaLabel?: string;
}

export const RecommendationsComponent = ({
  title,
  sectionName = 'complete the look',
  arrowLeft,
  arrowRight,
  rowsOnMobile = 'Two',
  maxCarouselItems = 8,
  signingEnabled,
  visibleOnDesktop,
  visibleOnTablet,
  visibleOnMobile,
  recommendedProducts,
  recommendations,
  showQuickView = false,
  ariaLabel,
  productPrice,
}: RecommendationsSchema & RecommendationsProp): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalRecommendations' });

  const {
    configuration: { defaultCurrency },
    pageType,
  } = useStaticContext();
  const { pushToDataLayer } = useDataLayerContext();
  const [products, setProducts] = useState<Product[]>([]);
  const [leftHidden, setLeftHidden] = useState(true);
  const [rightHidden, setRightHidden] = useState(false);
  const [tileWidth, setTileWidth] = useState(0);
  const [eventOccurred, setEventOccurred] = useState(false);
  const [eventProducts, setEventProducts] = useState<{ [k in SectionName]: EventProduct[] }>({
    'complete the look': [],
    'you may also like': [],
    'recently viewed': [],
  });
  const carouselRef = useRef<HTMLDivElement>(null);
  const tilesWrapperRef = useRef<HTMLDivElement>(null);

  const detectScrollEnd = (e: UIEvent) => {
    const { scrollLeft, offsetWidth, scrollWidth } = e.target as HTMLDivElement;

    setLeftHidden(scrollLeft === 0);
    setRightHidden(scrollLeft + offsetWidth > scrollWidth - 1);
    setEventOccurred(true);
  };

  const arrowClick = (direction: string) => {
    if (carouselRef.current) {
      if (direction === 'left') {
        carouselRef.current.scrollLeft -= tileWidth;
      } else {
        carouselRef.current.scrollLeft += tileWidth;
        setEventOccurred(true);
      }
    }
  };

  useEffect(() => {
    const rp = [...recommendedProducts];

    if (maxCarouselItems < recommendedProducts.length) {
      rp.splice(maxCarouselItems);
    }

    setProducts(rp);
    setRightHidden(rp.length <= (recommendations?.recommendedProducts?.maxProducts || 4));
  }, [maxCarouselItems, recommendations, recommendedProducts]);

  useEffect(() => {
    if (eventProducts[sectionName].length) {
      const eecImpressionsEvent = getProductDataLayerEvent('eecImpressions', {
        eventProducts: eventProducts[sectionName],
        sectionName,
        currency: defaultCurrency,
        pageType,
      });

      if (eecImpressionsEvent) {
        pushToDataLayer(eecImpressionsEvent);
      }
    }
  }, [eventProducts, sectionName, defaultCurrency, pushToDataLayer, pageType]);

  return products && Array.isArray(products) && products.length ? (
    <S.RecommendationsWrapper
      data-testid={sectionName.replaceAll(' ', '-')}
      $visibleOnDesktop={visibleOnDesktop}
      $visibleOnTablet={visibleOnTablet}
      $visibleOnMobile={visibleOnMobile}
      data-cs-capture=""
      role="region"
      aria-label={ariaLabel}
    >
      <S.Title testId="carousel-title" data-cs-capture="" variant="h1" component="h2">
        {title ||
          `${(() => {
            if (sectionName === 'complete the look') {
              return t('titleCTL');
            }

            if (sectionName === 'recently viewed') {
              return t('titleRV');
            }

            if (sectionName === 'you may also like') {
              return t('titleYMAL');
            }

            return '';
          })()}`}
      </S.Title>
      <S.CarouselWrapper onScroll={detectScrollEnd} ref={carouselRef}>
        <S.LeftArrow
          data-testid="carousel-arrow-left"
          data-cs-capture=""
          aria-label={arrowLeft || t('arrowLeft')}
          $leftHidden={leftHidden}
          onClick={() => arrowClick('left')}
        >
          <ArrowLeftLineIcon color={colors.WHITE} />
        </S.LeftArrow>
        <S.RightArrow
          data-testid="carousel-arrow-right"
          data-cs-capture=""
          aria-label={arrowRight || t('arrowRight')}
          $rightHidden={rightHidden}
          onClick={() => arrowClick('right')}
        >
          <ArrowRightLineIcon color={colors.WHITE} />
        </S.RightArrow>
        <S.Wrapper
          $count={products.length}
          $rowsOnMobile={rowsOnMobile}
          $maxItemsDesktop={recommendations?.recommendedProducts?.maxProducts || 4}
          $maxItemsTablet={recommendations?.recommendedProducts?.maxTabletProducts || 4}
          $maxItemsMobile={recommendations?.recommendedProducts?.maxMobileProducts || 2}
          ref={tilesWrapperRef}
          data-cs-capture=""
        >
          {products.map((product, i) => (
            <RecommendationsTile
              key={i}
              rowsOnMobile={rowsOnMobile}
              signingEnabled={signingEnabled}
              index={i}
              product={product as RecentlyViewedProduct}
              sectionName={sectionName}
              eventProducts={eventProducts}
              eventOccurred={eventOccurred}
              setTileWidth={setTileWidth}
              setEventProducts={setEventProducts}
              showQuickView={showQuickView}
              productPrice={productPrice}
            />
          ))}
        </S.Wrapper>
      </S.CarouselWrapper>
    </S.RecommendationsWrapper>
  ) : (
    <></>
  );
};
